<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>版本</a-breadcrumb-item>
              <a-breadcrumb-item>套餐记录</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div></div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <a-modal
          title="详情"
          :visible="visible"
          :width="700"
          @ok="onclose"
          @cancel="onclose"
        >
          <div class="flow-d-row" style="flex-wrap: wrap">
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="支付方式"
            >
              <span>{{ this.form.appIdWx == null ? '支付宝' : '微信' }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单号"
            >
              <span>{{ this.form.orderNo }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="上级订单号"
            >
              <span>{{ this.form.orderNoUp }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="下级订单号"
            >
              <span>{{ this.form.orderNoDown }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单金额"
            >
              <span>{{ this.form.amount }}元</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="支付金额"
            >
              <span>{{ this.form.amountPayed }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label=" 退款金额"
            >
              <span>{{ this.form.amountRefund }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="商品名称"
            >
              <span>{{ this.form.subject }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="付款日期"
            >
              <span>{{ this.form.date }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单创建时间"
            >
              <span>{{ this.form.createTime }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单支付时间"
            >
              <span>{{ this.form.payTime }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单退款时间"
            >
              <span>{{ this.form.refundTime }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="订单状态"
            >
              <span>{{
                this.form.state == 0
                  ? '待付款'
                  : text == 1
                  ? '付款成功'
                  : '付款异常'
              }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="行为状态"
            >
              <span>{{
                this.form.action == 0
                  ? '待处理'
                  : text == 1
                  ? '处理成功'
                  : '处理异常'
              }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="透传参数"
            >
              <span>{{ this.form.linkMsg }}</span>
            </a-form-model-item>
            <a-form-model-item class="flow-d-row" label="付款人ID">
              <span>{{
                this.form.buyerWx == null
                  ? this.form.buyerAlipay
                  : this.form.buyerWx
              }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="width: 50%"
              class="flow-d-row"
              label="应用ID"
            >
              <span>{{
                this.form.appIdWx == null
                  ? this.form.appIdAlipay
                  : this.form.appIdWx
              }}</span>
            </a-form-model-item>
          </div>
        </a-modal>
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="orderquerylist"
            :rowClassName="rowClassName"
          >
            <span slot="appIdWx" slot-scope="text, record">
              {{ record.appIdWx == null ? '支付宝' : '微信' }}
            </span>
            <span slot="createTime" slot-scope="text">
              {{ text | time }}
            </span>
            <span slot="payTime" slot-scope="text">
              {{ text | time }}
            </span>
            <span slot="state" slot-scope="text">
              {{ text == 0 ? '待付款' : text == 1 ? '付款成功' : '付款异常' }}
            </span>
            <span slot="action" slot-scope="text">
              {{ text == 0 ? '待处理' : text == 1 ? '处理成功' : '处理异常' }}
            </span>
            <template slot="act" slot-scope="text, record">
              <a @click="onEdit(record)">详情</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="total"
            @change="onpagesize"
          />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '订单号',
    dataIndex: 'orderNo',
  },
  {
    title: '支付方式',
    dataIndex: 'appIdWx',
    scopedSlots: { customRender: 'appIdWx' },
  },
  {
    title: '上级订单号',
    dataIndex: 'orderNoUp',
  },
  {
    title: '订单金额',
    dataIndex: 'amount',
  },
  {
    title: '商品名称',
    dataIndex: 'subject',
  },
  {
    title: '付款日期',
    dataIndex: 'date',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' },
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    scopedSlots: { customRender: 'payTime' },
  },
  {
    title: '订单状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '行为状态',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'act' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visible: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },

      params: {
        current: 1,
        pageSize: 10,
        id: {
          value: '1',
          op: 'isNotNull',
        },
      },
      total: 0,
      orderquerylist: [],
      form: {
        id: '',
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    onclose() {
      this.visible = false
    },
    onEdit(e) {
      this.visible = true
      this.form = e
    },
    // 编辑
    onpagesize(e) {
      this.params.current = e
      this.getorderquery()
      // console.log(this.params.current);
    },
    async getorderquery() {
      try {
        const res = await http.getorderquery(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.orderquerylist = data.orders.records
          this.total = data.orders.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getorderquery()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getorderquery()
  },
  computed: {},
}
</script>
<style lang="less" scoped>
span {
  display: inline-block;
}
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  //   height: 60px;
  margin-bottom: 5px;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
